export default function Footer() {
  return (
    <div className="font-sfpro text-xs text-grey-400">
      <a
        href="https://support.pocketcasts.com/article/ads-terms-and-conditions/"
        className="text-blue-500"
        target="_blank"
        rel="noreferrer"
      >
        Terms and conditions
      </a>{" "}
      •{" "}
      <span>
        For any questions about our ads contact us at{" "}
        <a
          href="mailto:bookings@pocketcasts.com"
          className="text-blue-500"
          target="_blank"
          rel="noreferrer"
        >
          bookings@pocketcasts.com
        </a>
      </span>
    </div>
  );
}
