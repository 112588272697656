export default function HeartIcon({ className }) {
  return (
    <svg
      className={className}
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.877 2.40263C12.8836 0.475181 10.5278 -0.280329 8.60036 0.707188C8.24937 0.879707 7.93408 1.11171 7.65448 1.37941L7.14287 1.84343L6.6509 1.39131C5.75856 0.504926 4.4974 0.105754 3.26597 0.313966C2.03455 0.504331 0.975645 1.27769 0.416449 2.38418C-0.339062 3.85951 -0.047566 5.65608 1.13627 6.82207L6.71217 12.5628C6.93823 12.7948 7.31301 12.8007 7.55097 12.5687C7.55097 12.5628 7.55692 12.5568 7.55692 12.5568L13.1251 6.82207C14.3089 5.65013 14.6064 3.84761 13.8509 2.36872L13.877 2.40263Z"
        fill="currentColor"
      />
    </svg>
  );
}
