import { usePage } from "@inertiajs/react";
import { formatPrice } from "../../lib/formatters";
import HeartIcon from "../ui/icons/HeartIcon";
import TopBanner from "../ui/TopBanner";

export default function PromoCodeBanner() {
  const { promocode } = usePage().props;

  if (!promocode) return null;

  const discountedAmount = promocode.amount_off
    ? formatPrice(promocode.amount_off)
    : `${promocode.percent_off}%`;

  return (
    <TopBanner animate>
      <div className="sm:w-[724px] mx-auto">
        <div className="flex flex-col sm:flex-row justify-start gap-y-2 gap-x-4 items-center w-full">
          <span>Code {promocode.code} applied</span>
          <HeartIcon className="hidden sm:inline text-[#FB5246]" />
          <span>Showing {discountedAmount} off all prices</span>
        </div>
      </div>
    </TopBanner>
  );
}
