import StarIcon from "../ui/icons/StarIcon";
import TopBanner from "../ui/TopBanner";

export default function SaleBanner() {
  return (
    <TopBanner variant="warm">
      <div className="flex gap-2 overflow-hidden -translate-x-24">
        {Array(8)
          .fill(null)
          .map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="flex gap-2 whitespace-nowrap flex-1 items-center uppercase"
            >
              <span>Summer Sale</span>
              <StarIcon className="text-white" />
              <span>10% off August premium Ads</span>
              <StarIcon className="text-white" />
            </div>
          ))}
      </div>
    </TopBanner>
  );
}
