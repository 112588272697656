import classNames from "classnames";
import { useEffect, useState } from "react";
import usePrefersReducedMotion from "../../hooks/usePrefersReducedMotion";

export default function TopBanner({
  animate = false,
  variant = "dark",
  children,
}) {
  const prefersReducedMotion = usePrefersReducedMotion();
  const [isShowing, setIsShowing] = useState(prefersReducedMotion || !animate);

  useEffect(() => {
    if (prefersReducedMotion || !animate) {
      return () => {};
    }
    const timeout = setTimeout(() => setIsShowing(true), 0);
    return () => clearTimeout(timeout);
  }, [animate, prefersReducedMotion]);

  const isDarkVariant = variant === "dark";
  const isWarmVariant = variant === "warm";

  return (
    <div
      className={classNames(
        "text-white transition-[max-height] ease-out delay-200 duration-[600ms] z-50 overflow-hidden shrink-0 pl-10 lg:pl-14 pr-10 lg:pr-0",
        { "max-h-0": !isShowing },
        { "max-h-24 sm:max-h-14": isShowing },
        { "bg-black ": isDarkVariant },
        {
          "bg-gradient-to-r from-[#F43769] to-[#FB8746]": isWarmVariant,
        }
      )}
    >
      <div className="flex justify-between gap-44">
        <div className="py-4 flex-1">{children}</div>
        <div className="hidden lg:block w-[25vw]" />
      </div>
    </div>
  );
}
