export default function StarIcon({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.88889 5.85403L13.0283 1.71461L14.2854 2.97168L10.146 7.11111H16V8.88889H10.146L14.2854 13.0283L13.0283 14.2854L8.88889 10.146V16H7.11111V10.146L2.97168 14.2854L1.71461 13.0283L5.85403 8.88889H0V7.11111H5.85403L1.71461 2.97168L2.97168 1.71461L7.11111 5.85403V0H8.88889V5.85403Z"
        fill="currentColor"
        fillOpacity="0.33"
      />
    </svg>
  );
}
