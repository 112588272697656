import { createInertiaApp } from "@inertiajs/react";
import { createRoot } from "react-dom/client";
import Layout from "../components/application/Layout";

createInertiaApp({
  resolve: async (name) => {
    const pages = import.meta.glob("../pages/**/*.jsx");
    const page = (await pages[`../pages/${name}.jsx`]()).default;
    page.layout = page.layout || ((content) => <Layout>{content}</Layout>);
    return page;
  },
  setup({ el, App, props }) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    createRoot(el).render(<App {...props} />);
  },
});
