import Footer from "./Footer";
import PromoCodeBanner from "./PromoCodeBanner";
import SaleBanner from "./SaleBanner";

export default function Layout({ children }) {
  return (
    <>
      <SaleBanner />
      <PromoCodeBanner />
      <div className="pl-10 lg:pl-14 pr-10 lg:pr-0 flex flex-col h-full">
        <main className="flex-1 relative">{children}</main>
        <footer className="py-5 flex items-center">
          <Footer />
        </footer>
      </div>
    </>
  );
}
